.page-title {
  color: rgb(255, 145, 0);
  font-size: 30px;
  margin: 50px;
  padding: 10px;
  letter-spacing: 20px;
  border: 1px solid rgb(255, 145, 0);
  border-radius: 100px;
  @media (max-width: 600px) {
    font-size: 20px !important;

  }
}

.p-head{
  @media (max-width: 600px) {
    text-align: center;
    padding-top: 50px;
  }
}
