.main-header {
  position: absolute;
  z-index: 99;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 600px) {
    justify-content: center !important;
  }

  .nav-links {
    margin-right: 40px;
    @media (max-width: 600px) {
      margin-right: 10px !important;

      li {
        font-size: 17px !important;
        padding: 15px !important;
        letter-spacing: 0px !important;
      }
    }
    li {
      list-style: none;
      float: left;
      padding: 20px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 3px;
    }
    a {
      color: inherit;
    }
    a:hover {
      color: rgb(255, 145, 0);
    }
  }
}
