body {
  font-family: "Poppins", sans-serif;
  background-color: #161d2d;
}
.bg-body {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/bg.jpeg);
  background-repeat: no-repeat;
  filter: brightness(20%);
  height: 100vh;
  position: absolute;
}

.c-body {
  z-index: 99;
  position: absolute;
  // margin-top: 130px;
  font-size: 19px;
  color: aliceblue;
  letter-spacing: 2px;
  @media (max-width: 600px) {
    text-align: center;
    .landing {
      width: 100% !important;
      padding: 10px 10px !important;
      text-align: center;
    }
    .icon-container {
      width: 100% !important;
    }
  }
}

.img-container {
  z-index: 99;
  img {
    width: 200px;
    height: 260px;
    border-radius: 100px;
    border: 5px solid aliceblue;
  }
}

.v-line {
  height: 100%;
  border-left: 5px solid aliceblue;
  border-radius: 5px;
}

.i-text {
  color: rgb(255, 145, 0);
}

.detail {
  font-weight: 600;
  a {
    color: aliceblue;
  }
}

.contact-page {
  @media (max-width: 600px) {
    text-align: left !important;
    width: 100% !important;
  }
  p {
    padding-top: 20px;
  }
  a {
    color: inherit;
  }
}

@media (max-width: 600px) {
  .illis {
    display: none;
  }
}

.skill-set {
  @media (max-width: 600px) {
    text-align: justify;
  }
}
