.custom-card {
  border: 1px solid rgb(255, 145, 0);
  padding: 20px 20px 0px 20px;
  width: 500px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px;

  .head-txt {
    color: rgb(255, 145, 0);
  }
}
