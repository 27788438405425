.skill {
  border: 1px solid rgb(255, 145, 0);
  color: aliceblue;
  padding: 10px;
  border-radius: 5px;
  margin: 20px;
  @media (max-width: 600px) {
    margin: 10px !important;
  }
}
